<template>
  <div class="main-box" >
    <!-- 面包屑 -->
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/personalCenter/MyOrder"
          >我的订单</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">商品评价</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="comment-detail">
      <h4 class="detail-name">商品评价<span>订单编号：{{orderDetail.code}}</span></h4>
      <div class="comment-box" v-for="(item,index) in productList" :key="item.id">
        <div class="comment-inner-l">
          <img class="cover" :src="item.productPicture" alt="">
          <p class="name">{{item.productName}}</p>
          <!-- <span>2021年第3期：2020/12/15-2021/01/23</span> -->
        </div>
        <div class="comment-inner-r">
          <!-- 好评-花 -->
          <div class="flower-five">
            <span>描述评价：</span>
            <img v-for="i in 5" @click="onMouseenter(index,i)" :key="i" :src="i <= item.evaluation ? flowerActive : flower" alt="">
          </div>
          <a-textarea
            v-model="item.comment"
            class="textarea"
            :auto-size="{ minRows: 6, maxRows: 7 }"
            placeholder="说两句，帮助更多想买的的人" 
          />
        </div>
      </div>
      <div class="foot-btn">
        <p @click="$router.go(-1)">取消</p>
        <p class="all-btn-small" @click="onSave">提交评价</p>
      </div>
    </div>
    <TipsModal :isPublicTipsShow="isPublicTipsShow" :maskClosable="true" :isCancelBtnShow="false" ConfirmBtnText="我知道了" tipsText="您已成功发表评论"  @setEvent="getEvent" />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: { },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      isPublicTipsShow: false,
      flower: require('@/assets/image/order/flower.png'),  // 初始状态花
      flowerActive: require('@/assets/image/order/flower-active.png'),  // 选中状态花
      flowerIndex: 0,
      productList: null,
      orderDetail: {},
      orderId: '',
      isLoading:false
    }
  },
  // 事件处理器
  methods: {
    getEvent(){
      this.isPublicTipsShow = false
      this.$router.push({
        path: "/personalCenter/MyOrder",
      });
    },
    onMouseenter(index, i){
      this.productList[index].evaluation = i
      this.$forceUpdate()
    },
    // 提交评价
    onSave(){
      let _params = this.productList.map((item) => {
        return {
          comment: item.comment,
          evaluation: item.evaluation,
          orderId: item.orderId,
          productId: item.productId,
        };
      });

      if(this.isLoading){return}
      this.isLoading = true;

      this.$ajax({
        url: "/hxclass-pc/order-comment/",
        method: "post",
        params: _params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.isPublicTipsShow = true
        } else {
          this.$message.error(res.message);
        }
        this.isLoading = false;
      });
    },
    // 查询详情
    getOrderDetail(){
      this.$ajax({
        url:'/hxclass-pc/order/' + this.orderId,
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.orderDetail = res.data;

          let arr = res.data.productList.filter(item=>{
            return item.productType != 4 && item.type == 1;
          })
          this.productList = arr.map((item) => {
            item["comment"] = ""; //点评
            item["evaluation"] = 5; //评价 1.差评,2.中评,3.中评,4.好评,5.好评
            item["orderId"] = this.orderId; //订单id
            return item;
          });
        }else{
          this.$message.error(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    this.getOrderDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
}
.comment-detail{
  margin-top: 22px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  .detail-name{
    font-size: 24px;
    font-weight: 500;
    color: @theme;
    line-height: 36px;
    &::before{
      content: '';
      display: inline-block;
      position: relative;
      top: 2px;
      width: 4px;
      height: 19px;
      background: @theme;
      margin-right: 8px;
    }
    span{
      margin-left: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
  }

  .comment-box{
    margin-top: 21px;
    border: 1px solid #EBEBEB;
    border-top: 3px solid @theme;
    min-height: 340px;
    display: flex;
    .comment-inner-l{
      width: 330px;
      text-align: center;
      padding-top: 55px;
      border-right: 1px solid #EBEBEB;
      font-weight: 400;
      color: #333333;
      line-height: 27px;
      font-size: 18px;
      .cover{
        display: inline-block;
        width: 130px;
        height: 113px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .name{
        width: 80%;
        margin: 0 auto;
        margin-top: 32px;
      }
      span{
        display: inline-block;
        margin-top: 20px;
        font-size: 12px;
        color: #666666;
      }
    }
    .comment-inner-r{
      padding: 48px 102px 48px 40px;
      flex: 1;
      .flower-five{
        span{
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
        }
        img{
          position: relative;
          bottom: 4px;
          width: 19.5px;
          height: 24px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
      .textarea{
        margin-top: 32px;
        min-height: 174px;
        width: 100%;
      }
      /deep/ .ant-input{
        font-size: 18px;
        padding: 15px 20px;
      }
      /deep/ .ant-input:hover{
        border-color: @theme
      }
    }
  }
  .foot-btn{
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    p{
      text-align: center;
      margin: 0 27px;
      width: 138px;
      height: 40px;
      line-height: 40px;
      border: 1px solid @theme;
      border-radius: 20px;
      color: @theme;
      cursor: pointer;
    }
    .all-btn-small{
      color: #fff;
      &::after{
        background: none;
      }
    }
  }
}
</style>
